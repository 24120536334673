<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-account-group</v-icon>
               <h2>¿Cuantas personas hay en su hogar?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-text-field
                    v-model="personas"
                    :error-messages="personasErrors"
                    label="Cantidad de personas"
                    outlined
                    @input="setPersonas($event)"
                    @blur="$v.personas.$touch()"
                    inputmode="numeric"
                ></v-text-field>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/municipio"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q1"
                    :disabled="$v.$invalid"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      personas: { required, numeric },
    },

    data() {
        return {
            personas: ''
        }
    },

    mounted() {
        this.personas = this.$store.state.personas
        this.$store.state.progress = 10
        this.goBackIfEmpty()
    },

    computed: {
        personasErrors () {
            const errors = []
            if (!this.$v.personas.$dirty) return errors
            !this.$v.personas.required && errors.push('Cantidad de personas es requerido.')
            !this.$v.personas.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setPersonas(value) {
            this.$store.state.personas = value
            this.$v.personas.$touch()
        },
         goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }
       }
    },
}
</script>